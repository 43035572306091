<template>
  <iframe
    :src="coachingHubUrl"
    width="100%"
    height="100%"
  />
</template>

<script>
export default {
  name: 'CoachingHub',
  computed: {
    coachingHubUrl() {
      return process.env.VUE_APP_COACHING_HUB;
    }
  }
};
</script>

<style scoped>
iframe {
  border: 0;
}
</style>
